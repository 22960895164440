import { z } from "zod";

// Message types supported by extension can be found at
// https://github.com/pineparkhealth/pinecone/blob/main/src/messaging/types.ts#L3
export const MessageType = {
  DICTATION_START: "DICTATION_START",
  DICTATION_STOP: "DICTATION_STOP",
  FLASH: "FLASH",
  GET_AUTH_TOKEN: "GET_AUTH_TOKEN",
  GET_TAB_ID: "GET_TAB_ID",
  GET_TRANSCRIPTS: "GET_TRANSCRIPTS",
  IFRAME_URL: "IFRAME_URL",
  INITIATE_AUTH_FLOW: "INITIATE_AUTH_FLOW",
  LOGOUT: "LOGOUT",
  NAVIGATE: "NAVIGATE",
  OPEN_BILLING_MODAL: "OPEN_BILLING_MODAL",
  OPEN_IMAGE_ORDER_MODAL: "OPEN_IMAGE_ORDER_MODAL",
  OPEN_LAB_ORDER_MODAL: "OPEN_LAB_ORDER_MODAL",
  OPEN_ORDER_MODAL: "OPEN_ORDER_MODAL",
  OPEN_REFERRAL_MODAL: "OPEN_REFERRAL_MODAL",
  OPEN_REPORT: "OPEN_REPORT",
  OPEN_RX_FORM_MODAL: "OPEN_RX_FORM_MODAL",
  OPEN_VISIT_NOTE: "OPEN_VISIT_NOTE",
  RECORDING_PAUSE: "RECORDING_PAUSE",
  RECORDING_START: "RECORDING_START",
  RECORDING_VOLUME: "RECORDING_VOLUME",
  REFRESH_CHART_TIMELINE: "REFRESH_CHART_TIMELINE",
  REFRESH_PROBLEM_LIST: "REFRESH_PROBLEM_LIST",
  REFRESH_VISIT_NOTE: "REFRESH_VISIT_NOTE",
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type MessageType = (typeof MessageType)[keyof typeof MessageType];

const successResponseSchema = z.object({
  success: z.literal(true),
});
const getTabIdResponseSchema = successResponseSchema.extend({
  tabId: z.number().nullish(),
});

const startRecordingResponseSchema = successResponseSchema.extend({
  message: z.string().nullish(),
});

const sampleRecordingResponseSchema = successResponseSchema.extend({
  message: z.string().nullish(),
  volume: z.number().nullish(),
});

const getTranscriptsResponseSchema = successResponseSchema.extend({
  transcripts: z.array(z.string()).nullish().default([]),
});

const messageSchemaMap = {
  [MessageType.DICTATION_START]: successResponseSchema,
  [MessageType.DICTATION_STOP]: successResponseSchema,
  [MessageType.FLASH]: successResponseSchema,
  [MessageType.GET_AUTH_TOKEN]: successResponseSchema,
  [MessageType.GET_TAB_ID]: getTabIdResponseSchema,
  [MessageType.GET_TRANSCRIPTS]: getTranscriptsResponseSchema,
  [MessageType.IFRAME_URL]: successResponseSchema,
  [MessageType.INITIATE_AUTH_FLOW]: successResponseSchema,
  [MessageType.LOGOUT]: successResponseSchema,
  [MessageType.NAVIGATE]: successResponseSchema,
  [MessageType.OPEN_BILLING_MODAL]: successResponseSchema,
  [MessageType.OPEN_VISIT_NOTE]: successResponseSchema,
  [MessageType.OPEN_ORDER_MODAL]: successResponseSchema,
  [MessageType.OPEN_IMAGE_ORDER_MODAL]: successResponseSchema,
  [MessageType.OPEN_LAB_ORDER_MODAL]: successResponseSchema,
  [MessageType.OPEN_REFERRAL_MODAL]: successResponseSchema,
  [MessageType.OPEN_REPORT]: successResponseSchema,
  [MessageType.OPEN_RX_FORM_MODAL]: successResponseSchema,
  [MessageType.RECORDING_PAUSE]: successResponseSchema,
  [MessageType.RECORDING_START]: startRecordingResponseSchema,
  [MessageType.RECORDING_VOLUME]: sampleRecordingResponseSchema,
  [MessageType.REFRESH_PROBLEM_LIST]: successResponseSchema,
  [MessageType.REFRESH_CHART_TIMELINE]: successResponseSchema,
  [MessageType.REFRESH_VISIT_NOTE]: successResponseSchema,
} satisfies Record<MessageType, typeof successResponseSchema>;

const errorResponseSchema = z.object({
  error: z.any(),
  success: z.literal(false),
});

export type SendMessageArgs<Type extends MessageType> = {
  [key: string]: unknown;
  type: Type;
};
export async function sendMessage<Type extends MessageType>(
  message: SendMessageArgs<Type>
): Promise<z.infer<(typeof messageSchemaMap)[Type]> | null | undefined> {
  if (!("chrome" in window)) {
    return Promise.resolve(null);
  }

  if (!("runtime" in chrome)) {
    return Promise.resolve(null);
  }

  const messageResponseSchema = z.discriminatedUnion("success", [
    messageSchemaMap[message.type],
    errorResponseSchema,
  ]);

  try {
    const parsedResponse = await Promise.any(
      window.env.EXTENSION_IDS.map(
        id =>
          new Promise((resolve, reject) => {
            chrome.runtime.sendMessage(id, message, response => {
              if (chrome.runtime.lastError) {
                return reject(chrome.runtime.lastError);
              }

              const parsed = messageResponseSchema.safeParse(response);
              if (!parsed.success) {
                return reject(response);
              }

              if (!parsed.data.success) {
                return reject(parsed.data);
              }

              resolve(parsed.data);
            });
          })
      )
    );

    // cast response to the correct schema type - this is safe because we've already
    // validated the response in the previous step
    return parsedResponse as z.infer<(typeof messageSchemaMap)[Type]>;
  } catch (error) {
    if (!(error instanceof AggregateError)) {
      throw error;
    }

    // Ignore errors that are caused by the extension not being installed
    const nonConnectionErrors = error.errors.filter(
      err =>
        !(
          err instanceof Error &&
          err.message.includes("Receiving end does not exist")
        )
    );
    if (nonConnectionErrors.length > 0) {
      return;
    }

    throw error;
  }
}
